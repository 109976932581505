class Policies {
  constructor() {
    this.getPolicies();
    $( document ).ajaxSuccess(function() {
      var chk1 = $("input[type='checkbox'][name='agree']");

    });
  }

  getPolicies(){
    $('.content').each(function(){
      var element = $(this)
      $.ajax({
        type: "GET",
        url: `https://mobilematcher.co.uk/policies/${element.attr('data-page')}?brand=deals.megamobiledeals.com`,
        success: function(res) {
          element.html(res.html)
          if(element.attr('data-page') == "tooltip"){
            element.html(`<a href="javascript:;" class="partner-text text-decoration-none">Partners</a>
          <div class="modal4 popup" id="tooltip-model">
            <div class="modal4-dialog modal-lg mx-auto" role="document">
              <div class="modal4-content px-md-3 px-2">
                <div class="d-flex align-items-center justify-content-between">
                  <h2 class="text-center font-weight-bold m-0">Our Partners</h2>
                  <span class="close-btn1" aria-hidden="true">&times;</span>
                </div>
                <div class="modal4-body">
                  <form class="partner-form" id="partner-form">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" name="partners-tp" class="custom-control-input" id="dukeleads-fzco">
                            <label class="custom-control-label text-left text-secondary label-size" for="dukeleads-fzco">Duke Leads - FZCO</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" name="partners-tp" class="custom-control-input" id="o2">
                            <label class="custom-control-label text-left text-secondary label-size" for="o2">O2</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" name="partners-tp" class="custom-control-input" id="discountexperts">
                            <label class="custom-control-label text-left text-secondary label-size" for="discountexperts">Discount Experts</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" name="partners-tp" class="custom-control-input" id="utilita">
                            <label class="custom-control-label text-left text-secondary label-size" for="utilita">Utilita</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" name="partners-tp" class="custom-control-input" id="SunshineMobile">
                            <label class="custom-control-label text-left text-secondary label-size" for="SunshineMobile">Sunshine Mobile</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" name="partners-tp" class="custom-control-input" id="YesCatalogue">
                            <label class="custom-control-label text-left text-secondary label-size" for="YesCatalogue">Yes Catalogue</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                          <input type="checkbox" name="partners-tp" class="custom-control-input" id="getaloantoday" >
                              <label class="custom-control-label text-left text-secondary label-size" for="getaloantoday">Get A Loan Today</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="custom-control custom-checkbox mr-sm-2">
                          <input type="checkbox" name="partners-tp" class="custom-control-input" id="switchexperts" >
                              <label class="custom-control-label text-left text-secondary label-size" for="getaloantoday">Switch Experts</label>
                          </div>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <style type="text/css">

            .label-size{
              font-size: 13px !important;
              font-weight: 400 !important;
            }
            .modal4 {
              display: none;
              position: fixed;
              z-index: 1;
              padding-top: 40px;
              padding-bottom: 40px;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              background-color: rgb(0,0,0);
              background-color: rgba(0,0,0,0.4);
            }
            .modal4-content {
              background-color: #fefefe;
              margin: auto;
              padding: 20px;
              border: 1px solid #888;
              width: 85%;
              border-radius: 5px;
            }
            .close-btn1{
              color: #aaaaaa;
              float: right;
              font-size: 24px;
              font-weight: bold;
            }
            .close-btn1:hover,
            .close-btn1:focus {
              color: #000;
              text-decoration: none;
              cursor: pointer;
            }
          </style>
          <script type="text/javascript">
              window.onload = (event) => {
              TogglePopUp()
              ToggleCheckBox()
              };

              function TogglePopUp() {
                $( ".close-btn1" ).click(function() {
                $('.modal4').hide();
                })

                $('.partner-text').click(function(){
                  $('.modal4').show();
                })
              }

              function ToggleCheckBox(){
                var chk1 = $("input[type='checkbox'][name='agree']");
                var chk2 = $("input[type='checkbox'][name='partners-tp']");

                chk1.on('change', function(){
                  chk2.prop('checked',this.checked);
                });
              }
          </script>`);
            let inputs  = document.querySelectorAll('.custom-control-input')
            for(let i in inputs){
              inputs[i].checked = true
            }
          }
        },
        dataType: "json"
      })
    })
  }

  partners(){

  }
}
export default new Policies();
